@tailwind base;

@layer base {
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
  }

@tailwind components;
@tailwind utilities;

body {
  margin-top: 0;
  padding-top: 0;
}